import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function BurgerMenuIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <>
          <path d="M0 0h24v24H0z" fill="none" />
          <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </>
      }
    />
  );
}
